/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from '../../api.js';
import { useTable } from 'react-table';
import { SpinnerDotted } from 'spinners-react';
import OpenedArtistCard from './OpenedArtistCard/OpenedArtistCard.js';
import { FaArrowDownShortWide, FaArrowDownWideShort } from "react-icons/fa6";
import { IoSearchSharp } from "react-icons/io5";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { ru } from 'date-fns/locale/ru';
registerLocale('ru', ru)
import 'react-datepicker/dist/react-datepicker.css';

const AllArtistsYandex = () => {
    function notifySucc() { return toast.info("Артисты были успешно добавлены в пак!") }
    function notifyErr() { return toast.error("Артисты уже есть в этом паке") }
    // Date Formatting Function
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const [currentPage, setCurrentPage] = useState(1)

    // State Variables
    const [artistName, setArtistName] = useState('')
    // МЕСТО "ОТ" & "До"
    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);

    // Прослушивания "ОТ" & "До"
    const [minListens, setMinListens] = useState(null);
    const [maxListens, setMaxListens] = useState(null);

    // Жанры и страны
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedGenre, setSelectedGenre] = useState('');

    const placeHolders = {
        minListens: 1,
        maxListens: 1000000000,
        minValue: 1,
        maxValue: 365000
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: 'var(--c5_s)',
            backgroundColor: 'var(--c5d2_s)',
            color: 'var(--c2_s)',
            minWidth: '186px',
            padding: '5px',
            height: '20px',
            minHeight: '31px',
            maxHeight: "31px",
            padding: '0px',
            margin: '0px',
            cursor: "pointer",
            '&:hover': {
                borderColor: 'var(--c5_s)',
            }
        }),

        singleValue: (provided) => ({
            ...provided,
            color: 'var(--c2_s)',
            fontSize: '16px',
            padding: '0px',
            margin: '0px',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: 'var(--c5_s)',
            color: 'var(--c2_s)',
            padding: '0px',
            margin: '0px',
            fontSize: "15px",
            marginLeft: "5px",
            maxHeight: "25px",
            borderRadius: "5px",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: 'var(--c_s)',
            color: 'var(--c2_s)',
            padding: 5,
            cursor: "pointer",
            '&:hover': {
                backgroundColor: 'var(--c_sd)',
                color: 'var(--c2_s)',
            }
        }),
        menu: (provided) => ({
            ...provided,
            color: 'var(--c2_s)',
            backgroundColor: 'var(--c_s)',
            border: '1px solid #ccc',

        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'var(--err_s)',
            padding: '0px',
            margin: '0px',
            '&:hover': {
                backgroundColor: 'var(--err_s2)',
                color: 'var(--err_s)',
            }
        }),
        clearIndicator: (provided) => ({
            ...provided,
            color: 'var(--err_s)',
            padding: '0px',
            margin: '0px',
            '&:hover': {
                color: 'var(--err_s)',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#999', // Цвет текста плейсхолдера
            // fontStyle: 'italic', // Стиль шрифта
            padding: '0px',
            margin: '0px',

        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0px',
            margin: '0px',
            marginLeft: "5px",
        }),
        input: (provided) => ({
            ...provided,
            padding: '0px',
            margin: '0px',

        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            padding: '0px',
            margin: '0px',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: 'var(--c5_s)',
            padding: '0px',
            margin: '0px',
            '&:hover': {
                color: 'var(--c2_s)',
            }
        })
    };

    const [artistOpened, setArtistOpened] = useState(false);
    const [showUnlabeled, setShowUnlabeled] = useState(false);
    const [globalFilter, setGlobalFilter] = useState([]);
    const currentDate = new Date();
    const twoWeeksAgo = new Date(currentDate);
    twoWeeksAgo.setDate(currentDate.getDate() - 7)
    currentDate.setDate(currentDate.getDate() - 0)

    const [timeRangeFrom, setTimeRangeFrom] = useState(formatDate(twoWeeksAgo));
    const [timeRangeTo, setTimeRangeTo] = useState(formatDate(currentDate));

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [myPacks, setMyPacks] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedPack, setSelectedPack] = useState([]);
    const [notifiCount, setNotifiCount] = useState(0);
    const [showPackColumn, setShowPackColumn] = useState(true);
    const [markedRows, setMarkedRows] = useState([]);
    const [myPropSetted, setMyPropSetted] = useState(false);
    const [myProp, setMyProp] = useState({});
    const [countriesList, setCountries] = useState([]);
    const [genresList, setGenres] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const throttleTimeout = useRef(null);
    const [maxPages, setMaxPages] = useState(0)
    const [redisKey, setRedisKey] = useState([])
    const [debouncedPage, setDebouncedPage] = useState(currentPage);
    const [pageCount, setPageCount] = useState(maxPages)
    const [isSorting, setIsSorting] = useState(true)
    const [lastDefUpdate, setlastDefUpdate] = useState(false)


    const navigate = useNavigate();

    const [timeRange, setTimeRange] = useState([null, null]);
    const [startDate, setStartDate] = useState(new Date());


    const [activeDates, setActiveDates] = useState([])

    useEffect(() => {
        if (timeRange[0] && timeRange[1] && timeRange[0] > timeRange[1]) {
            setTimeRange([timeRange[1], timeRange[0]]);
        }
        setTimeRangeFrom(timeRange[0] ? formatDate(timeRange[0]) : formatDate(twoWeeksAgo))
        setTimeRangeTo(timeRange[1] ? formatDate(timeRange[1]) : formatDate(currentDate))
    }, [timeRange]);

    const unMarkAll = () => {
        setMarkedRows([]);
    };

    const getActualDates = () => {
        api.get(api.upload_dates)
            .then(packData => {
                const availableDates = packData.available_dates.map(d => new Date(d));
                setActiveDates(availableDates)
            })
            .catch(error => 'aga');
    }

    // API Functions
    const getMyPacks = () => {
        api.get(api.packs)
            .then(packData => {
                sessionStorage.setItem('packData', JSON.stringify(packData));
                setMyPacks(packData);
                if (packData.length > 0) {
                    setSelectedPack(packData[0].pack_id);
                }
            })
            .catch(error => 'aga');
    };

    const getMyGenres = () => {
        api.get(api.getGenres)
            .then(data => {
                const uniqueGenres = data.filter((genre, index, self) =>
                    index === self.findIndex((g) => g.name === genre.name)
                ).sort((a, b) => a.name.localeCompare(b.name));

                setGenres([...uniqueGenres]);
            });
    };


    const getMyCountries = () => {
        api.get(api.getCountries)
            .then(data => {
                const uniqueCountries = data.filter((country, index, self) =>
                    index === self.findIndex((c) => c.name === country.name)
                ).sort((a, b) => a.name.localeCompare(b.name));

                setCountries([...uniqueCountries]);
            });
    };

    const metricks = ['rating', 'listeners_count', 'delta_listeners_count']
    const [isOrder, setIsOrder] = useState(true)
    const [orderSortMetric, setOrderSortMetric] = useState('')
    const [lastOrderMetrics, setLastOrderMetrics] = useState('')


    const handleSort = (colID) => {
        const exceptions = ['artist_name', ' ',];

        if (exceptions.includes(colID)) {
            return;
        }

        // Если клик на уже выбранный метод сортировки, меняем направление
        if (orderSortMetric === colID) {
            setIsOrder(prevState => !prevState);
        } else {
            // Иначе, устанавливаем новый метод сортировки и сбрасываем направление на true
            setOrderSortMetric(colID);
            setIsOrder(true);
        }
    };

    useEffect(() => {
        if (orderSortMetric) {
            search(true);
        }
    }, [orderSortMetric, isOrder]);


    useEffect(() => {
        if (artistName || minValue || maxValue || minListens || maxListens || selectedCountry.length > 0 || selectedGenre.length > 0) {
            setIsSorting(false)
        } else {
            setIsSorting(true)
        }
    }, [artistName, minValue, maxValue, minListens, maxListens, selectedCountry, selectedGenre])

    const search = (sortClick = false) => {
        var body = {}
        setIsLoading(true);

        if (artistName || minValue || maxValue || minListens || maxListens || selectedCountry.length > 0 || selectedGenre.length > 0) {

            if (sortClick) {
                setIsLoading(false);
                return
            }
            body.from_date = timeRangeFrom
            body.to_date = timeRangeTo
            artistName ? body['artist_name'] = artistName : ''
            minValue ? body['from_rating'] = minValue : '';
            maxValue ? body['to_rating'] = maxValue : '';
            minListens ? body['from_listeners'] = minListens : '';
            maxListens ? body['to_listeners'] = maxListens : '';
            selectedCountry ? body['countries'] = selectedCountry : '';
            selectedGenre ? body['genres'] = selectedGenre : '';
            body.order = true
            // return

            api.post(api.artistYandexFilter, body)
                .then(response => {
                    setMaxPages(response.pages)
                    setRedisKey(response.redis_key_filter)
                    setPageCount(response.pages)
                    if (response.pages) {
                        setPageCount(response.pages)
                    } else {
                        setData([])
                        setIsLoading(false);
                        return
                    }
                    api.get(api.artistYandexRedisFilter(response.redis_key_filter, 1))
                        .then(data => {
                            setlastDefUpdate(false)
                            setData(data);
                        })
                        .then(() => {
                            setIsLoading(false);
                        })
                })
        } else {
            body = {
                from_date: timeRangeFrom,
                to_date: timeRangeTo,
                order_type: orderSortMetric ? orderSortMetric : metricks[0],
                order: isOrder,
            };
            api.post(`${api.artistYandex}`, body)
                .then(response => {
                    setMaxPages(response.total_pages)
                    setRedisKey(response.redis_key)
                    if (response.total_pages) {
                        setPageCount(response.total_pages)
                    } else {
                        setData([])
                        setIsLoading(false);
                        return
                    }
                    api.get(api.artistYandexRedis(response.redis_key, 1))
                        .then(data => {
                            setlastDefUpdate(true)
                            setData(data);
                        })
                        .then(() => {
                            setIsLoading(false);
                        })
                })

        }
    }



    useEffect(() => {
        fetchData();
        return () => clearTimeout(throttleTimeout.current);
    }, []);

    const fetchData = () => {
        search();
        getMyPacks();
        getMyGenres();
        getMyCountries();
        getActualDates()
    };

    const handleListensInputChange = (value, isMin) => {
        const numValue = value ? Math.max(1, Number(value.replace(/\D/g, ''))) : ''; // Убираем все нечисловые символы и применяем минимальное значение 1
        if (isMin) {
            setMinListens(numValue);
        } else {
            setMaxListens(numValue);
        }
    };

    const handleRatingInputChange = (value, isMin) => {
        const numValue = value ? Math.max(1, Number(value.replace(/\D/g, ''))) : ''; // Убираем все нечисловые символы и применяем минимальное значение 1
        if (isMin) {
            setMinValue(numValue);
        } else {
            setMaxValue(numValue);
        }
    };



    const handleCountryChange = (selectedOptions) => {
        setSelectedCountry(selectedOptions.map((sel) => (sel.value)));
    };

    const handleGenreChange = (selectedOptions) => {
        setSelectedGenre(selectedOptions.map((sel) => (sel.value)));
    };


    const countriesOptions = countriesList.map(country => ({
        value: country.name,
        label: (country.name.length <= 25)
            ? country.name
            : country.name.split(' ').map(word => word[0]).join('')
    }));

    const genresOptions = genresList.map(genre => ({
        value: genre.name,
        label: genre.name
    }));


    const handleSearchClick = () => {
        search();
    };

    const handleButtonClick = (row) => {
        window.open(`/yandex/artist-card/${row.artist_id}`, '_blank');
    };

    const handleRowClick = (row) => {
        handleCheckboxChange(row);
    };

    const [selectedFilredArtists, setSelectedFilredArtists] = useState([])
    const handleMarkedRow = () => {
        const newMarkedArtists = data.filter(artist => markedRows.includes(artist.artist_id));
        setSelectedFilredArtists((prevSelected) => {
            const combinedArtists = [...prevSelected, ...newMarkedArtists];
            var truesh = combinedArtists.filter((artist, index, self) =>
                index === self.findIndex((a) => a.artist_id === artist.artist_id))
            const lastMarked = truesh.filter(artist => markedRows.includes(artist.artist_id));

            return lastMarked
        });
    };

    useEffect(() => {
        handleMarkedRow();
    }, [markedRows]);


    const handleCheckboxChange = (row) => {
        const rowId = row.artist_id;
        const isRowMarked = markedRows.includes(rowId);
        const updatedMarkedRows = isRowMarked
            ? markedRows.filter(id => id !== rowId)
            : [...markedRows, rowId];

        setMarkedRows(updatedMarkedRows);

    };

    const handleChange = (event) => {
        event.stopPropagation();
        setSelectedOption(event.target.value);
    };

    function gotoPage(num) {
        setIsLoading(true)
        if (lastDefUpdate) {
            if (num <= 0) {
                num = 1;
            } else if (num > pageCount) {
                return;
            }
            api.get(api.artistYandexRedis(redisKey, num))
                .then(data => {
                    setCurrentPage(num);
                    setData(data);
                })
                .catch(error => {
                    console.error('Error loading page:', error);
                }).finally(() => {
                    setIsLoading(false)
                })
        } else {
            if (num <= 0) {
                num = 1;
            } else if (num > pageCount) {
                return;
            }
            api.get(api.artistYandexRedisFilter(redisKey, num))
                .then(data => {
                    setCurrentPage(num);
                    setData(data);
                })
                .catch(error => {
                    console.error('Error loading page:', error);
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const [theme, setTheme] = useState('dark')

    useEffect(() => {
        const handler = setTimeout(() => {
            gotoPage(debouncedPage);
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [debouncedPage]);



    const addToPack = () => {
        const body = { artist_ids: markedRows };
        var rootElement = document.getElementById('bodyID').className
        setTheme(rootElement.replace('chakra-ui-light', ''))
        console.log(selectedPack)

        api.post(api.add_to_pack(selectedPack), body)
            .then(data => {
                notifySucc()
                unMarkAll()
                search()
            })
            .catch(error => {
                notifyErr()
                unMarkAll()
                search()
            })
    }


    const columns = useMemo(() => [
        {
            Header: ' ',
            accessor: 'select',
            width: '40px',
            Cell: ({ row }) => (
                <label className='customCheckbox'>
                    <input
                        id="checkbx"
                        type="checkbox"
                        checked={markedRows.includes(row.original.artist_id)}
                        readOnly
                    />
                    <span></span>
                </label>
            ),
        },
        {
            Header: 'Имя артиста',
            accessor: 'artist_name',
            width: '210px',
            maxWidth: '210px',
        },
        {
            Header: 'Рейтинг',
            accessor: 'rating',
            width: '215px',
        },
        {
            Header: 'Новые слушатели',
            accessor: 'delta_listeners_count',
        },
        {
            Header: 'Слушатели',
            accessor: 'listeners_count',
        },
        {
            Header: 'Изменение рейтинга',
            accessor: 'delta_rating',
        },
        {
            Header: 'Лейбл',
            accessor: 'label_name',
        },
        {
            Header: ' ',
            Cell: ({ row }) => (
                <div className='actions-div'>
                    <div className='btn' onClick={(event) => {
                        event.stopPropagation();
                        handleButtonClick(row.original)
                    }}>Подробнее</div>
                </div>
            ),
            width: '150px',
        },
    ], [markedRows]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = useTable({
        columns,
        data,
        manualPagination: true,
        pageCount: maxPages,
    });




    function previousPage(num) {
        setIsLoading(true)
        if (num <= 1) {
            setIsLoading(false)
            return;
        }
        if (lastDefUpdate) {
            api.get(api.artistYandexRedis(redisKey, num - 1))
                .then(data => {
                    setCurrentPage(num - 1);
                    setData(data);
                })
                .catch(error => {
                    console.error('Error loading next page:', error);
                }).finally(() => {
                    setIsLoading(false)
                })
        } else {
            if (num >= pageCount) {
                setIsLoading(false)
                return;
            }
            api.get(api.artistYandexRedisFilter(redisKey, num - 1))
                .then(data => {
                    setCurrentPage(num - 1);
                    setData(data);
                })
                .catch(error => {
                    console.error('Error loading page:', error);
                }).finally(() => {
                    setIsLoading(false)
                })
        }
    }

    function nextPage(num) {
        setIsLoading(true)
        if (lastDefUpdate) {
            if (num >= pageCount) {
                setIsLoading(false)
                return;
            }
            api.get(api.artistYandexRedis(redisKey, num + 1))
                .then(data => {
                    setCurrentPage(num + 1);
                    setData(data);
                })
                .catch(error => {
                    setData([]);
                }).finally(() => {
                    setIsLoading(false)
                })
        } else {
            if (num >= pageCount) {
                setIsLoading(false)
                return;
            }
            api.get(api.artistYandexRedisFilter(redisKey, num + 1))
                .then(data => {
                    setCurrentPage(num + 1);
                    setData(data);
                })
                .catch(error => {
                    setData([]);
                }).finally(() => {
                    setIsLoading(false)
                })
        }
    }


    return (
        <div style={{ position: 'relative' }}>
            <ToastContainer theme={theme} autoClose={3000} />
            {notification &&
                <div className='Categories notification-packadded'>
                    <img className='arrow-img' src='/elements/icons/check-mark-button-svgrepo-com.svg' alt='' />
                    <div className='np-label'>Артисты: {notifiCount} были добавлены в подборку</div>
                </div>
            }
            {artistOpened
                ? <OpenedArtistCard />
                : <>
                    <form className={`artist-filter-form ${isLoading ? 'loading-inactive' : ''}`}>
                        <div className='firstBlock'>
                            <h3>Все артисты</h3>
                            {/* <p className='lastUpdateTime'>Последнее обновление: </p> */}
                        </div>
                        <div className='lablesss'>
                            <div className='inputsi secondInp inactive'>
                                <label className='range-slider rs_bl'>Исполнитель:</label>
                                <input
                                    style={{ width: "100%" }}
                                    type="text"
                                    id="artist"
                                    className="sinput222"
                                    name="artist"
                                    placeholder="Имя артиста"
                                    onChange={(e) => setArtistName(e.target.value)}
                                />
                            </div>

                            <div className="inputsi secondInp">
                                <div className="range-slider">
                                    <div className="min-Max">От:</div>
                                    <h3 className="min-Max">Даты</h3>
                                    <div className="min-Max">До:</div>
                                </div>
                                <div className='fkkkl'>
                                    <DatePicker
                                        className='sinput222'

                                        selected={timeRange[0]}
                                        onChange={(dates) => setTimeRange(dates)}  // Передавай массив дат
                                        startDate={timeRange[0]}
                                        endDate={timeRange[1]}
                                        selectsRange
                                        minDate={new Date('2023-01-01')}
                                        maxDate={new Date()}
                                        highlightDates={activeDates}
                                        dateFormat="dd.MM.yyyy"
                                        locale="ru"
                                        placeholderText="Выберите диапазон"
                                        isClearable
                                    />
                                </div>

                                {/* <div className='fkkkl'>
                                    <input
                                        type="date"
                                        name="minValue"
                                        className="sinput222"
                                        value={timeRangeFrom.toLocaleString('ru-RU')}
                                        onChange={(e) => setTimeRangeFrom(e.target.value)}
                                    />
                                    <input
                                        type="date"
                                        name="maxValue"
                                        className="sinput222 sinput222_2"
                                        value={timeRangeTo.toLocaleString('ru-RU')}
                                        onChange={(e) => setTimeRangeTo(e.target.value)}
                                    />
                                </div> */}
                            </div>

                            <div className="inputsi secondInp ">
                                <div className="range-slider">
                                    <div className="min-Max">От:</div>
                                    <h3 className="min-Max">Рейтинг</h3>
                                    <div className="min-Max">До:</div>
                                </div>
                                <div className='fkkkl'>
                                    <input
                                        type="text"
                                        name="minValue"
                                        className="sinput222"
                                        value={minValue ? minValue : ''}
                                        placeholder={placeHolders.minValue.toLocaleString('ru-RU')}
                                        onChange={(e) => handleRatingInputChange(e.target.value, true)}

                                    />
                                    <input
                                        type="text"
                                        name="maxValue"
                                        className="sinput222 sinput222_2"
                                        value={maxValue ? maxValue : ''}
                                        placeholder={placeHolders.maxValue.toLocaleString('ru-RU')}
                                        onChange={(e) => handleRatingInputChange(e.target.value, false)}

                                    />
                                </div>
                            </div>

                            <div className="inputsi secondInp">
                                <div className="range-slider">
                                    <div className="min-Max">От:</div>
                                    <h3 className="min-Max">Слушатели</h3>
                                    <div className="min-Max">До:</div>
                                </div>
                                <div className='fkkkl'>
                                    <input

                                        type="text"
                                        name="minListens"
                                        className="sinput222"
                                        value={minListens ? minListens : ''}
                                        placeholder={placeHolders.minListens.toLocaleString('ru-RU')}
                                        onChange={(e) => handleListensInputChange(e.target.value, true)}

                                    />
                                    <input
                                        type="text"
                                        name="maxListens"
                                        className="sinput222 sinput222_2"
                                        value={maxListens ? maxListens : ''}
                                        placeholder={placeHolders.maxListens.toLocaleString('ru-RU')}
                                        onChange={(e) => handleListensInputChange(e.target.value, false)}

                                    />
                                </div>
                            </div>
                            <div className='inputsi secondInp inactive'>
                                <div className="range-slider">
                                    Страна:
                                </div>
                                <div className='fkkkl'>
                                    <Select
                                        // value={selectedCountry}
                                        onChange={handleCountryChange}
                                        options={countriesOptions}
                                        isMulti
                                        placeholder="Выберите страну"
                                        // className='sinput222 selectlib'
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className='inputsi secondInp inactive'>
                                <div className="range-slider">
                                    Жанры:
                                </div>
                                <div className='fkkkl'>
                                    <Select
                                        // value={selectedGenre}
                                        onChange={handleGenreChange}
                                        options={genresOptions}
                                        isMulti
                                        placeholder="Выберите жанр"
                                        // className='sinput222 selectlib'
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                            <div className='btn find-btn' style={{ alignSelf: "end", justifySelf: "center", maxWidth: "150px", margin: "0" }} onClick={handleSearchClick}> Найти <IoSearchSharp className='find-btn-ico' /></div>
                            <div className='btn find-btn err-btn' style={{ marginTop: "0px" }} onClick={() => { window.location.reload(true) }}>Очистить</div>
                        </div>
                    </form>
                    <div className='tableNload-block'>
                        {isLoading ?
                            <div className='spinnerDiv'>
                                <SpinnerDotted size="100px" color='#356dc2' className="spinner" />
                            </div>
                            :
                            ''
                        }
                        <>
                            <div style={{ overflow: 'auto', minHeight: "500px", maxHeight: "500px", position: "relative" }}>
                                {data.length > 0 ? <table className={`main-table-block ${isLoading ? 'loading-inactive' : ''}`} {...getTableProps()} style={{ width: '100%', opacity: isLoading ? '40%' : '100%' }}>
                                    <thead className='main-tableheader-block'>
                                        {headerGroups && headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        className='header-row'
                                                        onClick={() => isSorting && !['artist_name', ' ',].includes(column.id) ? handleSort(column.id) : ('')}
                                                        // onClick={() => isSorting && !['artist_name', ' ',].includes(column.id) ? handleSort(column.id) : ('')}
                                                        style={{ width: column.width }}
                                                        {...column.getHeaderProps()}>
                                                        <div className='flexBox'>
                                                            {column.render('Header')}
                                                            {isSorting ? (orderSortMetric === column.id ? (isOrder ? <FaArrowDownShortWide /> : <FaArrowDownWideShort />) : "") : ''}
                                                        </div>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {rows.map((row, index) => {
                                            prepareRow(row);
                                            const rowStyle = markedRows.includes(row.original.artist_id) ? 'selected-row' : '';
                                            return (
                                                <tr
                                                    className={`row-main-block ${index % 2 === 0 ? 'background-colored-row' : ''} ${rowStyle}`}
                                                    onClick={() => handleRowClick(row.original)}
                                                    {...row.getRowProps()}>
                                                    {row.cells.map((cell, index) => (
                                                        <td
                                                            style={{
                                                                borderRadius: index === 0 ? '5px 0 0 5px' : index === 7 ? '0 5px 5px 0' : '',
                                                                textAlign: [2, 3, 4, 5, 6].includes(index) ? 'center' : '',
                                                                padding: '5px 10px',
                                                            }}
                                                            {...cell.getCellProps()}
                                                        >
                                                            {cell.render('Cell')}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table> : <p className='notfound-text'> {!isLoading ? "Ничего не найдено" : ""}</p>}
                            </div>
                            <div className='btns-page' style={{ opacity: isLoading ? '40%' : '100%', userSelect: isLoading ? 'none' : 'auto' }}>
                                <button className='btn back-btn' onClick={() => previousPage(currentPage)} >Назад</button>
                                <span className='btns-page-span'>
                                    Страница{' '}
                                    <input
                                        className='sinput'
                                        type="number"
                                        value={currentPage}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const intValue = parseInt(value, 10); // Преобразуем строку в целое число
                                            if (!isNaN(intValue) && intValue >= 0) { // Проверяем, что это число
                                                setCurrentPage(intValue); // Обновляем состояние `currentPage`
                                                setDebouncedPage(intValue); // Обновляем состояние `debouncedPage` для отложенного вызова
                                            }
                                        }}
                                        style={{ width: '50px', marginRight: "5px" }}
                                    />
                                    <strong> из {maxPages}</strong>
                                </span>
                                <button className='btn next-btn' onClick={() => nextPage(currentPage)} >Далее</button>
                            </div>
                        </>
                    </div>
                </>
            }
            {markedRows.length > 0 ?
                <div className='markedrows-control'>
                    Выбранные артисты:
                    <div className='selected-artists'>
                        {selectedFilredArtists.map((artist, index) => (
                            <div className='selectedArtist' key={index}>{artist.artist_name}</div>
                        ))}
                        <div className='selectedArtist err-btn' onClick={() => { unMarkAll() }}>Отменить</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <div className="custom-select">
                            <select
                                className='sinput222'
                                style={{ minWidth: "150px" }}
                                value={selectedPack}
                                onChange={(e) => { setSelectedPack(e.target.value) }}>
                                {Array.isArray(myPacks) && myPacks.map((option, index) => (
                                    <option
                                        key={index}
                                        packname={option.title}
                                        value={option.pack_id}>{option.title}</option>
                                ))}
                            </select>
                        </div>
                        <div onClick={() => { addToPack() }} className='sinput222 btn alt'>Добавить в пак</div>
                    </div>
                </div> : null}
        </div>
    );
};

export default AllArtistsYandex;
