import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Platform from "./platform-selector.js";
import AllArtists from './WorkSpaces/AllArtists.js';
import AllArtistsYandex from './WorkSpaces/AllArtists_Yandex.js';
import Profile from './profile/profile.js';
import OpenedArtistCard from './WorkSpaces/OpenedArtistCard/OpenedArtistCard.js';
import OpenedYandexArtistCard from './WorkSpaces/OpenedArtistCard/OpenedYandexArtistCard.js';
import MyPacks from './WorkSpaces/MyPacks.js';
import MyPacksYandex from './WorkSpaces/MyPacks_Yandex.js';
import MyUniquePack from './WorkSpaces/MyUniquePack.js';
import MyUniquePackYandex from './WorkSpaces/MyUniquePack_Yandex.js';
import NewBackground from '../BG/new_background.js';
import LandingPage from '../components/big/landing_block.jsx';
import Header from './Header_туц.js'

import ProfileDD from '../components/medium/profile.jsx'

const Workspace = () => {

    return (
        <>
            <Header />
            <div className='workspace'>
                <div className='LeftSide bgt2'>
                    <Platform />
                </div>

                <div className='RightSide_ws bgt'>
                    <Routes>
                        {/* <Route path="/" element={<LandingPage />} /> */}

                        <Route path="/vk-test" element={<AllArtists />} />
                        <Route path="/vk-test/artist-card/:artistName" element={<OpenedArtistCard />} />

                        <Route path="*" element={<Navigate to={"/yandex"} replace />} />
                        <Route path="/" element={<Navigate to={'/yandex'} />} />
                        <Route path="/profile" element={<ProfileDD />} />
                        <Route path="/yandex/my-packs" element={<MyPacksYandex />} />
                        <Route path="/yandex/my-packs/:pack_id" element={<MyUniquePackYandex />} />
                        <Route path="/yandex/artist-card/:artistId" element={<OpenedYandexArtistCard />} />
                        <Route path="/yandex" element={<AllArtistsYandex />} />
                        <Route path="/profile" element={<Profile />} />
                    </Routes>
                </div>
            </div>
            <NewBackground />
        </>
    );
};

export default Workspace;
