/* eslint-disable */
import React, { useState, useEffect } from 'react';
import LoginBlock from './Login_Register_Components/login_block.js';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Workspace from './MainWorkSpace/workspace.js';
// import './App_old.css';
import './EXPERIMENT.CSS/1/NEW2024STYLE_dark.css'


function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');


  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [token]) 

  const handleToggle = () => {
    setIsDarkMode(!isDarkMode)
  };

  return (
    <>
      <Routes location={location}>
        <Route
          path="/login"
          element={
            <div className='mainDiv'>
              <LoginBlock />
            </div>
          }
        />
        <Route
          path="*"
          element={<Workspace />}
        />

      </Routes>
    </>
  );
}

export default App;
