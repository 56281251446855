import { useNavigate } from 'react-router-dom';

export const artistYandex = 'https://gm-analytics.ru/api/artist';
export const artistYandexFilter = 'https://gm-analytics.ru/api/artist_filter';
export const artistYandexRedis = (key, page) => `https://gm-analytics.ru/api/artist/${key}/${page}`;
export const artistYandexRedisFilter = (key, page) => `https://gm-analytics.ru/api/artist_filter/${key}/${page}`;
export const getStateMetricks = (artist_id) => `https://gm-analytics.ru/api/state_metrics/${artist_id}`
export const getArtistMetricks = (artist_id) => `https://gm-analytics.ru/api/metrics/${artist_id}`
export const getGenres = 'https://gm-analytics.ru/api/genres';
export const getCountries = 'https://gm-analytics.ru/api/countries';
export const login = 'https://gm-analytics.ru/api/login';
export const protect = 'https://gm-analytics.ru/api/protect';
export const list_artist = 'https://gm-analytics.ru/api/list_artist'
export const get_artist_info = 'https://gm-analytics.ru/api/get_artist_info';
export const get_artist_info_day = 'https://gm-analytics.ru/api/get_artist_info_day'
export const register = 'https://gm-analytics.ru/api/register';
export const create_role = 'https://gm-analytics.ru/api/create_role';
export const give_role = 'https://gm-analytics.ru/api/give_role';
export const create_pack = 'https://gm-analytics.ru/api/create_pack';
export const add_to_pack = (pack_id) => `https://gm-analytics.ru/api/pack/${pack_id}/artists`
export const delete_pack = 'https://gm-analytics.ru/api/delete_pack';
export const delete_from_pack = 'https://gm-analytics.ru/api/delete_from_pack';
export const show_all_pack = 'https://gm-analytics.ru/api/show_all_pack';
export const artist_list_blacklist = 'https://gm-analytics.ru/api/artist_list_blacklist';
export const list_artist_by_pack = 'https://gm-analytics.ru/api/list_artist_by_pack';
export const list_artist_by_pack_yandex = (pack_id) => `https://gm-analytics.ru/api/pack/${pack_id ? pack_id : ""}`
export const session_control = 'https://gm-analytics.ru/api/session_control';
export const delete_session = 'https://gm-analytics.ru/api/delete_session';
export const get_username = 'https://gm-analytics.ru/api/get_username';
export const packs = 'https://gm-analytics.ru/api/packs';
export const upload_dates = 'https://gm-analytics.ru/api/upload_dates';
export const pack = (packId) => `https://gm-analytics.ru/api/pack${packId ? "/" + packId : ''}`;



const token = localStorage.getItem('token')

export async function post(url, body, auth = true) {

    const headers = {
        'Content-Type': 'application/json',
        ...(auth && { 'Authorization': `Bearer ${token} ` })
    };
    
    const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
    });

    return await response.json();
}

export async function del(url, body) {

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token} `
        },
        body: JSON.stringify(body)
    });

    return await response.json();
}

export async function get(url, token = null) {
    const authToken = token || localStorage.getItem('token');


    const headers = {
        'Content-Type': 'application/json',
    };

    if (authToken) {
        headers['Authorization'] = `Bearer ${authToken} `;
    }

    const response = await fetch(url, {
        method: 'GET',
        headers: headers
    });

    if (!response.ok) {
        localStorage.clear()
    }

    return await response.json();
}
