import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as api from '../../api.js';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { SpinnerDotted } from 'spinners-react';
import OpenedArtistCard from './OpenedArtistCard/OpenedArtistCard.js';

const AllArtists = () => {
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    let mainRoute = 'test.gm-analytics.ru'
    let additionals = 'https://test.gm-analytics.ru/api/vk/artists/additionals'
    const [showUnlabeled, setShowUnlabeled] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const navigate = useNavigate();
    const [data, setdata] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [myPacks, setmyPacks] = useState('');

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        search();
    };

    const handleSearchClick = () => {
        search();
    };


    function search() {
        setIsLoading(true);
        api.get(additionals)
            .then(response => {
                return response
            })
            .then(newData => {
                setdata(newData);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    }


    const openArtistCard = (row) => {
        const artistNAME = row.artist_name
        navigate('/vk-test/artist-card/' + artistNAME)
    }


    const DataTable = ({ data, showUnlabeled }) => {
        const [markedRows, setMarkedRows] = useState([]);

        const handleCheckboxChange = (row) => {
            const rowId = row.artist_id;
            const isRowMarked = markedRows.includes(rowId);

            const updatedMarkedRows = isRowMarked
                ? markedRows.filter(id => id !== rowId)
                : [...markedRows, rowId];

            setMarkedRows(updatedMarkedRows);
        };

        const [pageIndex, setPageIndex] = useState(0);

        const columns = useMemo(() => [
            {
                Header: 'artist_name',
                accessor: 'artist_name',
                width: '250px',
                filter: 'between',
                disableSortRemove: true,
            },
            {
                Header: 'track_count',
                accessor: 'track_count',
                width: '250px',
                disableSortRemove: true,
            },
            {
                Header: ' ',
                Cell: ({ row }) => (
                    <div className='actions-div'>
                        <div className='btn' onClick={(event) => {
                            event.stopPropagation();
                            openArtistCard(row.original)
                        }}>Подробнее</div>
                    </div>
                ),
                width: '100px',
            },
        ], [markedRows, setMarkedRows]);


        const handleRowClick = (row) => {
            handleCheckboxChange(row);
        };

        const filteredData = useMemo(() => {
            if (showUnlabeled) {
                return data.filter(row => !row.pack_info.length);
            }
            return data;
        }, [data, showUnlabeled]);

        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            state,
            setGlobalFilter,
        } = useTable(
            {
                columns,
                data: filteredData,
                initialState: { pageIndex: 0, pageSize: 10, globalFilter: globalFilter || '' },
                autoResetPage: false,
                disableMultiSort: true,
            },
            useFilters,
            useGlobalFilter,
            useSortBy,
            usePagination
        );


        useEffect(() => {
            setPageIndex(state.pageIndex);
        }, [state.pageIndex]);

        useEffect(() => {
            setGlobalFilter(globalFilter);
        }, [globalFilter, setGlobalFilter]);

        const [selectedOption, setSelectedOption] = useState(
            Array.isArray(myPacks) && myPacks.length > 0 ? myPacks[0].pack_id : ''
        );

        const handleChange = (event) => {
            event.stopPropagation();
            setSelectedOption(event.target.value);
        };

        return (
            <>
                <div style={{ minHeight: "400px" }}>
                    <table className='main-table-block' {...getTableProps()} style={{ width: '100%', opacity: (isLoading ? '40%' : '100%'), pointerEvents: (isLoading ? 'none' : 'auto') }}>
                        <thead className='main-tableheader-block' style={{ minHeight: '200px' }}>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, index) => (
                                        <th
                                            className='header-row'
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, index) => {
                                prepareRow(row);
                                const rowStyle = markedRows.includes(row.original.artist_id) ? 'selected-row' : '';
                                return (
                                    <tr
                                        className={`row-main-block ${index % 2 === 0 ? 'background-colored-row' : ''} ${rowStyle}`}
                                        onClick={() => handleRowClick(row.original)}
                                        {...row.getRowProps()}>
                                        {row.cells.map((cell, index) => (
                                            <td
                                                style={{
                                                    borderRadius: index === 0 ? '5px 0 0 5px' : index === 5 ? '0 5px 5px 0' : '',
                                                    textAlign: index === 1 ? 'center' : index === 2 ? 'start' : '',
                                                    alignSelf: index === 2 && 'start',
                                                    padding: '5px 10px',
                                                }}
                                                {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='btns-page' style={{ opacity: (isLoading ? '40%' : '100%'), userSelect: (isLoading ? 'none' : 'auto') }}>
                    <button className='btn next-btn' onClick={() => previousPage()} disabled={!canPreviousPage}>Назад</button>
                    <span className='btns-page-span'>
                        Страница{' '}
                        <input
                            className='sinput'
                            type="number"
                            value={pageIndex + 1}
                            onChange={(e) => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                                gotoPage(pageNumber);
                            }}
                            style={{ width: '50px', marginRight: "5px" }}
                        />
                        <strong>
                            из {pageCount}
                        </strong>{' '}
                    </span>
                    <button className='btn prev-btn' onClick={() => nextPage()} disabled={!canNextPage}>Далее</button>
                </div>
            </>
        );
    };

    return (
        <div className='www'>
            <>
                <div className='artist-filter-form' style={{}}>
                    <div className='firstBlock'>
                        <h3>Все артисты</h3>
                    </div>
                    <div className='btn find-btn' onClick={handleSearchClick}>Найти</div>
                </div>
                <div className='tableNload-block'>
                    {
                        isLoading ?
                            <div className='spinnerDiv'>
                                <SpinnerDotted
                                    size="100px"
                                    color='#356dc2'
                                    className="spinner" />
                            </div>
                            : ''
                    }
                    <DataTable
                        data={data}
                        showUnlabeled={showUnlabeled}
                    />
                </div>
            </>
        </div>
    );
}

export default AllArtists;
