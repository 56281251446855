import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { SpinnerDotted } from 'spinners-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


import * as api from '../../../api.js';

const OpenedArtistCard = () => {
  const { artistName } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [dataArtist, setDataArtist] = useState([])
  const [usersData, setUsersData] = useState([])

  useEffect(() => {
    if (artistName) {
      getArtistList(artistName);
    }
  }, [artistName]);

  const getArtistList = (artistName) => {

    setIsLoading(true);

    let get_artist_info = `https://test.gm-analytics.ru/api/vk/artist/additionals/${artistName}`
    api.get(get_artist_info)
      .then(response => {
        setDataArtist(response)
      }).finally(() => {
        setIsLoading(false);
      })

    let get_users_info = `https://test.gm-analytics.ru/api/vk/artist/users/${artistName}`
    api.get(get_users_info)
      .then(response => {
        setUsersData(response)
      }).finally(() => {
        setIsLoading(false);
      })
  };

  const divStyles = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    transition: 'none',
    padding: '20px',
    borderRadius: '15px',
    // border: 'solid 2px white',
    background: 'rgba(0,0,0,0)'
  }


  return (
    <div className='artistcard-block'>
      <div className='ab-backbtn'>
        <div className='btn' style={{ width: '150px' }} onClick={() => { navigate('/vk-test') }}>Назад</div>
        <div className='btns-swtich'>
        </div>
      </div>
      <div className='ab-header'>
        <div className='ab-artist-name'>
          <div className='arti-name'>{artistName}</div>
          <div className='total-addict'>{dataArtist?.total_additions && `Кол-во добавленных аудиозаписей: ${dataArtist.total_additions}`}</div>
        </div>
      </div>
      <div className='ab-main-info'>
        {isLoading ? (
          <SpinnerDotted
            size="100px"
            style={{ color: "#356dc2", margin: '300px auto' }}
            color='#356dc2'
            className="spinner" />
        ) : (
          <>
            <div style={divStyles} >
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  data={dataArtist?.additionals_by_date}
                  margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className='total-addict'>{usersData?.total_users && `Кол-во пользователей: ${usersData.total_users}`}</div>
            <div style={divStyles} >
              <ResponsiveContainer width="100%" height={400}>
                <LineChart
                  data={usersData?.users_by_date}
                  margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OpenedArtistCard;
